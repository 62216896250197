@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');

body {
  font-family: 'PT Sans', sans-serif;
}

.login-container {
  height: 100vh;
  width: 100%;
}

.login-container > div {
  width: 100%;
  height: 100%;
}

.login-title {
  background-color: #009AFF;
  color: #ffffff;
  height: 100vh;
  padding-left: 4rem;
}

.login-box {
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-slight-gray {
  background-color: #F9FAFB;
}

div {
  transition: background-color 0.5s ease
}

.table-link {
  text-decoration: none;
}

.file-drop {
  border: 2px dashed;
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-radius: 0.375rem;
  height: 8rem;
  cursor: pointer;
}

.file-drop:hover {
  background-color: #F9FAFB;
}

.invalid-feedback {
  display: block !important;
}

.editor {
  overflow-x: scroll;
}

.editable-table-column {
  cursor: text;
}

.editable-table-column-input {
  max-width: 16rem;
  margin-right: 0.4rem;
}

.office-container {
  display: flex;
  justify-content: center;
}

.office-icon {
  background-color: #009AFF;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.25rem;
  color: #ffffff;
  font-size: 20px;
}

.office {
  cursor: pointer;
  transition: all 0.5s ease;
  margin-top: 1rem;
}

.office:hover {
  transform: translateY(-10px);
}

.update-report {
  height: 5rem;
  border: 1px solid;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.5rem;
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: all ease 0.5s;
  margin-bottom: 1rem;
  margin-left: 0;
  margin-right: 0;
}

.update-report:hover {
  border-color: #009AFF;
  border-width: 2px;
  border-radius: 0.75rem;
}

.update-report-active {
  border-color: #009AFF;
  border-width: 4px;
  border-radius: 12px;
}

.update-report-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  background-color: #009AFF;
  color: white;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.update-report-description {
  margin: auto;
  margin-left: 0;
}

.update-report-description > p {
  margin: 0;
}

.flip-horizontally {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}